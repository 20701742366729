<template>
<!-- positionSet -->
  <div class="set winStyle">
      <div class="main box box_column">
          <div class="top">
              <div class="box center">
                <div class="title">测点布设配置</div>
                <div class="shu"></div>
                <div class="mL30">图纸导入</div>
              </div>
          </div>
          <div class="bottom flex1 box box_column">
                <ul class="box center ul">
                    <li v-for="(item, index) in tabList" :key="index"  class="box around" @click="changeTab(item)">
                        <div class="box center ">
                            <div class="mR10">{{item.name}}</div>
                            <img src="../../assets/images/img/Combined Shape.png" alt="">
                        </div>
                    </li>
                </ul>
                <ul class="ul1 box center">
                    <li v-for="(item, index) in twoList" :key="index" :class="active == item.id?'activeColor':''"  @click="changeTwo(item.id)">
                        <div>{{item.name}}</div>
                    </li>
                </ul>
                <div class="flex1 box center" style="width:100%">
                    <div class="box box_column mR20" style="width: 60%; height:100%">
                        <div class="zhengBox mB20">
                            <zheng></zheng>
                        </div>
                         <div class="fuBox flex1">
                            <fu></fu>
                        </div>
                    </div>

                    <div class="ceBox flex1">
                        <ce></ce>
                    </div>
                </div>
          </div>
  
      </div>
  </div>
</template>

<script>

import zheng from './components/zheng';
import ce from './components/ce.vue'
import fu from './components/fu.vue'
export default {
  name: 'set',
  components: {
      zheng,
      ce,
      fu
  },
  data(){
      return{
          tabList:[],
          twoList:[],
          active:'',
      }
  },
  //创建
  created(){

  },

  //安装
  mounted(){
      this.aloneTypeData();
  },
  
  //方法
  methods:{
    
    //切换菜单显示二级菜单
    changeTab(item){
        console.log('item', item.list);
        this.twoList = item.list;
    },
    changeTwo(id){
        console.log('id',id);
        
        this.active = id;
    },


    // ---------------------------------请求数据-----------------------------------------

    aloneTypeData(){
        this.$axios.get(`${this.baseURL}synthesis/target/type`).then(res => {
            console.log('单桥类型列表', res);
            this.tabList = res.data.data;
            this.tabList.push({'id':'4','name':'视频监控'})
            this.twoList = this.tabList[0].list;
        }).catch(err=>{
        })
    },


  }


}
</script>
<style  scoped lang="scss">
    .set{
        height: 100%;
        .main{
            height: 100%;
        }
        .bottom{
            height: 100%;
        }
    }

    .title{
        padding-right: 30px;
    }
    .shu{
        width: 1px;
        height: 16px;
        background: #3A4A7B;
    }
    .ul{
        margin-top: 16px;
        margin-bottom: 16px;
        li{
            width: 118px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            border-radius: 16px;
            margin-right: 24px;
            border: 1px solid #2667DB;
        }
    }

    .zhengBox{
        width: 60%;
        height: 60%;
        margin-right: 8px;
    }
    .fuBox{
        width: 100%
    }
    .ceBox{
        height: 100%;
    }
    .ul1{
        margin-bottom: 25px;
        li{
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            background: #233361;
            border-radius: 15px;
            margin-right: 16px;
            color: #7387C6;
        }
    }
    .activeColor{
        background: #0050B4 !important;
        color: #fff !important;
    }

</style>
<template>
  <div class="healthySet box justify box_column">
    <!-- 添加构件弹框 -->
    <div class="addWin winStyle" v-if="addWin == 1">
      <div class="top">增加构件/指标</div>
      <div class="bottom flex1" style="height: 100%">
        <div class="heng"></div>
        <table class="tableWin" border="1" style="border-collapse: collapse; width: 100%">
          <tr>
            <th>部位</th>
            <th>系统</th>
            <th>构件/指标</th>
          </tr>
          <tr>
            <td>
              <el-select v-model="part" placeholder="请选择" @change="changeDownPart">
                <el-option v-for="item in partDownList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
            <td>
              <el-select v-model="sys" placeholder="请选择" @change="changeDownSys">
                <el-option v-for="item in sysDownList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </td>
            <td>
              <el-input v-model="inputName"></el-input>
            </td>
          </tr>
        </table>

        <div class="box around" style="margin-top: 127px">
          <div class="box center">
            <div class="btnSelect1 mR20 pointer" @click="closeWin">取消</div>
            <div class="btnSelect2 pointer" @click="winSave">保存</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 是否删除 -->
    <!-- <div class="detWin" v-if="detWin == 1">
      <div class="box box_column around">
        <div class="box around mB20">
          <img src="../../assets/images/healthy/jinggao-grey-icon.png" alt="" style="width: 60px; height: 54px" />
        </div>
        <div class="fs18" style="color: #333">请确认是否删除该构件/指标</div>
        <div class="box around mT50">
          <div class="box">
            <div class="btn1 mR40 pointer" @click="detWinClose">取消</div>
            <div class="btn2 pointer" @click="detLine">确认</div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <confirm :detWin="detWin" msg="请确认是否删除该构件/指标" @confirm="detLine" @close="detWinClose"></confirm> -->

    <!-- 是否应用    -->
    <!-- <div class="detWin" v-if="sureWin == 1">
      <div class="box box_column around">
        <div class="box around mB20">
          <img src="../../assets/images/healthy/jinggao-grey-icon.png" alt="" style="width: 60px; height: 54px" />
        </div>
        <div class="fs18" style="color: #333">请确认是否应用该设置</div>
        <div class="box around mT50">
          <div class="box">
            <div class="btn1 mR40 pointer" @click="sureClose">取消</div>
            <div class="btn2 pointer" @click="surebtn">确认</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <confirm :detWin="sureWin" msg="请确认是否应用该设置" @confirm="surebtn" @close="sureClose"></confirm> -->
    <!-- 错误框    -->
    <!-- <div class="detWin" v-if="errWin == 1">
      <div class="box box_column around">
        <div class="box around mB20">
          <img src="../../assets/images/healthy/jinggao-grey-icon.png" alt="" style="width: 60px; height: 54px" />
        </div>
        <div class="fs18" style="color: #333">{{ errName }}</div>
        <div class="box around mT50">
          <div class="box">
            <div class="btn1 mR40 pointer" @click="errClose">取消</div>
            <div class="btn2 pointer" @click="errbtn">确认</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <confirm :detWin="errWin" :msg="errName" @confirm="errbtn" @close="errClose"></confirm> -->

    <div class="box justify mB20">
      <div class="uiBox">
        <el-select v-model="num" placeholder="应用历史模板" @change="changeHis" v-if="type != 'check'">
          <el-option v-for="item in numList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div class="box">
        <div class="btnSelect1 mR20 pointer" @click="back">返回</div>
        <div class="btnSelect2 pointer" @click="saveBtn" v-if="setNum == 0">保存</div>
        <div class="btnSelect2 pointer" @click="countData" v-if="setNum == 1 && type == 'edit'">重新计算</div>
      </div>
    </div>
    <div class="flex1">
      <table class="border-table" border="1" style="border-collapse: collapse; width: 100%">
        <tr>
          <th>部位</th>
          <th>系统</th>
          <th>构件/指标</th>
          <th>测点名称</th>
          <th>部位权重</th>
          <th>系统权重</th>
          <th>构件/指标权重</th>
          <th>评分值</th>
          <th>修改值</th>
          <th>修改</th>
        </tr>
        <tr v-for="(item, index) in dataList" :key="index">
          <!-- 部位 -->
          <td :class="[{ partValue1: item.partId == 1 }, { partValue2: item.partId == 2 }]">
            {{ item.partName }}
          </td>
          <!-- 系统 -->
          <td :class="[{ sysValue1: item.systemId == 6 }, { sysValue3: item.systemId == 7 }]">
            {{ item.systemName }}
          </td>
          <!-- 构件/d指标 -->
          <td>
            {{ item.component }}
          </td>

          <td class="">
            <div class="selui">
              <el-select v-model="targetIds[index]" multiple collapse-tags placeholder="请选择">
                <el-option v-for="item in targetList[index]" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
          </td>
          <!-- 部位权重 -->
          <td :class="[{ partValue1: item.partId == 1 }, { partValue2: item.partId == 2 }]">
            <el-input v-if="item.partId == 1" v-model="partValue1" :disabled="disabled"></el-input>
            <el-input v-if="item.partId == 2" v-model="partValue2" :disabled="disabled"></el-input>
            <el-input v-if="item.partId == 3" v-model="partValue3" :disabled="disabled"></el-input>
            <!-- <el-input v-model="partValue[index]"></el-input> -->
          </td>
          <!-- 系统权重 -->
          <td :class="[{ sysValue9: item.systemId == 9 }, { sysValue10: item.systemId == 10 }, { sysValue13: item.systemId == 13 }, { sysValue15: item.systemId == 15 }, { sysValue16: item.systemId == 16 }, { sysValue17: item.systemId == 17 }, { sysValue18: item.systemId == 18 }]">
            <el-input v-if="item.systemId == 4" v-model="sysValue4" :disabled="disabled"></el-input>
            <el-input v-if="item.systemId == 5" v-model="sysValue5" :disabled="disabled"></el-input>
            <el-input v-if="item.systemId == 6" v-model="sysValue6" :disabled="disabled"></el-input>
            <el-input v-if="item.systemId == 7" v-model="sysValue7" :disabled="disabled"></el-input>
            <el-input v-if="item.systemId == 8" v-model="sysValue8" :disabled="disabled"></el-input>

            <!-- 附属设施 -->
            <el-input v-if="item.systemId == 10" v-model="sysValue10" :disabled="disabled"></el-input>
            <!-- 支座 -->
            <el-input v-if="item.systemId == 9" v-model="sysValue9" :disabled="disabled"></el-input>
            <!-- 主梁 -->
            <el-input v-if="item.systemId == 13" v-model="sysValue13" :disabled="disabled"></el-input>
            <!-- 上部承重结构 -->
            <el-input v-if="item.systemId == 18" v-model="sysValue18" :disabled="disabled"></el-input>

            <!-- 桥墩 -->
            <el-input v-if="item.systemId == 15" v-model="sysValue15" :disabled="disabled"></el-input>
            <!-- 桥台 -->
            <el-input v-if="item.systemId == 16" v-model="sysValue16" :disabled="disabled"></el-input>
            <!-- 墩台基础 -->
            <el-input v-if="item.systemId == 17" v-model="sysValue17" :disabled="disabled"></el-input>
          </td>
          <!-- 构件 -->
          <td>
            <el-input v-model="componentValue[index]" :disabled="disabled"></el-input>
          </td>
          <td class="textC">
            <div v-if="setNum == 0">/</div>
            <div v-if="setNum == 1">{{ item.grade }}</div>
          </td>
          <td class="textC" style="width: 100px">
            <div v-if="setNum == 0">/</div>
            <el-input v-model="item.gradeUpdate" :disabled="disabled" v-if="setNum == 1 && type == 'check'"></el-input>
            <!-- <div v-if="setNum == 1 && type=='check'">{{item.gradeUpdate}}</div> -->
            <!-- <div v-if="setNum == 1 && type=='edit'">{{item.gradeUpdate}}</div> -->
            <el-input v-model="gradeUpdate[index]" v-if="setNum == 1 && type == 'edit'"></el-input>
          </td>
          <td class="textC" :class="detStatus == 0 ? 'bg1' : ''">
            <span class="iconfont icon-shanchu pointer1" v-if="detStatus == 0"></span>
            <span class="iconfont icon-shanchu pointer" v-if="detStatus == 1" @click="detWinOpen(index, detStatus)"></span>
          </td>
        </tr>
      </table>
    </div>

    <!-- <div class="box justify boxBottom" v-if="setNum == 1">
      <div class="box around itemBox" style="width: 25%">
        <div>下部结构SBCI</div>
        <div>{{ sbci }}</div>
      </div>
      <div class="box around itemBox" style="width: 25%">
        <div>上部结构SPCI</div>
        <div>{{ spci }}</div>
      </div>
      <div class="box around itemBox" style="width: 25%">
        <div>桥面系BDCI</div>
        <div>{{ bdci }}</div>
      </div>
      <div class="box around itemBox" style="width: 25%">
        <div>总分</div>
        <div>{{ grade }}</div>
      </div>
    </div> -->

    <div class="box end mT10" v-if="type != 'check'">
      <div class="mR40 box center pointer" @click="getWin">
        <img src="../../assets/images/healthy/zengjia-grey-icon.png" alt="" class="mR10" />
        <div>增加构件/指标</div>
      </div>
      <div class="box center pointer" @click="getDetWin">
        <img src="../../assets/images/healthy/jianshao-grey-icon.png" alt="" class="mR10" />
        <div>删除构件/指标</div>
      </div>
    </div>
    <div>
      <table class="border-table table-weight-wrapper" border="1" style="border-collapse: collapse; width: 100%; margin-top: 40px">
        <tr>
          <th>评估类型</th>
          <th>总分</th>
          <th>下部结构SBCI</th>
          <th>上部结构SPCI</th>
          <th>桥面系BDCI</th>
          <th>检查时间</th>
          <th class="table-weight">
            <span class="weight-text">权重</span>
            <span class="weight-confirm" @click="changeText" v-if="type != 'check'">
              <span v-if="!toggle" data-id="1">修改</span>
              <span v-else>
                <span style="padding-right: 10px" data-id="2">保存</span>
                <span data-id="3">取消</span>
              </span>
            </span>
          </th>
        </tr>
        <tr>
          <td>健康监测</td>
          <td>{{ health.score }}</td>
          <td>{{ health.sbci }}</td>
          <td>{{ health.spci }}</td>
          <td>{{ health.bdci }}</td>
          <td>{{ health.timeO }}</td>
          <td class="table-weight">
            <span v-if="!toggle" class="table-weight-res">{{ health.factor }}</span>
            <input type="text" v-model="health.factor" @blur="changInputData('1')" v-else />
          </td>
        </tr>
        <tr>
          <td>定期检查</td>
          <td>{{ regular.score }}</td>
          <td>{{ regular.sbci }}</td>
          <td>{{ regular.spci }}</td>
          <td>{{ regular.bdci }}</td>
          <td>{{ regular.timeO }}</td>
          <td class="table-weight">
            <span v-if="!toggle" class="table-weight-res">{{ regular.factor }}</span>
            <input type="text" v-model="regular.factor" @blur="changInputData('2')" v-else />
          </td>
        </tr>
        <!-- <tr v-for="(item, index) in dataList" :key="index">
         
          <td :class="[{ partValue1: item.partId == 1 }, { partValue2: item.partId == 2 }]">
            {{ item.partName }}
          </td>
         
          <td :class="[{ sysValue1: item.systemId == 6 }, { sysValue3: item.systemId == 7 }]">
            {{ item.systemName }}
          </td>
         
          <td>
            {{ item.component }}
          </td>
        </tr> -->
      </table>
    </div>
  </div>
</template>

<script>
import setVue from "../position/set.vue";
import confirm from "../../components/confirm";
import { formateArray,trim } from "../../utils/utils";

export default {
  name: "healthySet",
  components: {
    confirm
  },
  data() {
    return {
      toggle: false,
      projectId: "",

      num: "", //历史选择
      numList: [], //历史页面

      partValue: [],
      sysValue: [],
      componentValue: [],
      gradeUpdate: [],

      dataList: [],

      partValue1: "",
      partValue2: "",
      partValue3: "",

      sysValue1: "",
      sysValue2: "",
      sysValue3: "",
      sysValue4: "",
      sysValue5: "",
      sysValue6: "",
      sysValue7: "",
      sysValue8: "",

      // 悬桥
      sysValue13: "", //主梁
      sysValue15: "", //桥墩
      sysValue16: "", //桥台
      sysValue17: "", //墩台基础
      sysValue18: "", //上部承重结构
      sysValue10: "",
      sysValue9: "",

      //  测点下拉
      targetList: [],
      targetIds: [],

      arr: [],

      // 添加构件
      addWin: 0, //构件弹框 0关闭 1关闭
      part: "",
      partDownList: [],
      sys: "",
      sysDownList: [],
      inputName: "", //构建名称

      setNum: "0",
      id: "",
      type: "",

      // check
      sbci: "", //下部评分
      spci: "", //上部评分
      bdci: "", //桥面
      grade: "", //评分
      disabled: true,

      // 删除窗口
      detWin: 0,
      detStatus: "0",
      detIndext: "",

      // 确认是否应用
      sureWin: "0",
      health: {
        score: null,
        timeO: null,
        sbci: null,
        spci: null,
        bdci: null,
        factor: null,
        oldFactor: null
      },
      regular: {
        score: null,
        timeO: null,
        sbci: null,
        spci: null,
        bdci: null,
        factor: null,
        oldFactor: null
      },
      // 输入错误窗口
      errWin: "0",
      errName: "",

      // 验证
      numberPass: "0" //0证明输入有误    1输入没有问题
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");

    var type = this.$route.query.type;
    this.active = this.$route.query.active;
    this.currentPage = this.$route.query.currentPage;
    this.type = type;
    this.id = this.$route.query.id;
    let name = "";
    if (type == "check") {
      // 查看
      name = "查看";
      this.setNum = 1;
      this.disabled = true;
      this.checkData();
    } else if (type == "edit") {
      //  编辑
      name = "编辑";

      this.setNum = 1;
      this.disabled = false;
      this.checkData();
    } else {
      //  设置
      name = "设置";

      this.disabled = false;
      this.setNum = 0; //设置的时候不需要显示评分
      this.tableData(); //获取设置内容
      this.partsDownData(); //获取弹框的部位下拉
      this.hisData(); //获取历史设置
    }
    let arr = this.$store.state.breadcrumbList;

    arr.push({ name });
    arr = formateArray(arr);
    console.log("米娜包鞋", arr);
    this.$store.commit("getBreadcrumb", arr);
  },

  //安装
  mounted() {
    //   this.partData();    //
  },

  //方法
  methods: {
    saveWeight() {
      let m = this.regular.factor * 100;
      let n = this.health.factor * 100;
      if (m < 0 || m > 100 || n < 0 || n > 100) {
        this.$message.error("输入为两位正数0至1小数");
        return;
      }
      this.regular.oldFactor = this.regular.factor.toString();
      this.health.oldFactor = this.health.factor.toString();
      let data = {
        healthFactor: this.health.factor.toString(),
        regularFactor: this.regular.factor.toString(),
        versionId: this.id
      };
      this.$axios.post(`${this.baseURL}health/add/weight/${this.projectId}`, this.$qs.stringify(data)).then((res) => {
        console.log("重新计算", res);
        this.sureWin = 0;
        // this.back();
        if (res.data.errCode == 10000) {
          this.$message.error(res.data.errMsg);
        }
      });
    },
    changInputData(flag) {
      console.log('this.regular.factor',this.regular.factor,'this.health.factor',this.health.factor)
      if (trim(this.regular.factor) === '' && trim(this.health.factor) === '') {
         console.log('trim===this.regular.factor',this.regular.factor,"this.health.factor",this.health.factor)
        this.regular.factor = "1"
        this.health.factor="0"
      }
      if (trim(this.regular.factor) === '' && trim(this.health.factor) !== '') {
         console.log('trim===this.regular.factor',this.regular.factor,"this.health.factor",this.health.factor)
        this.regular.factor = "0"
        flag=1
      }
      if (trim(this.regular.factor) !== '' && trim(this.health.factor) === '') {
         console.log('trim===this.regular.factor',this.regular.factor,"this.health.factor",this.health.factor)
      
        this.health.factor="0"
      }
     
      console.log('trim===this.regular.factor',this.regular.factor,"this.health.factor",this.health.factor)
      this.regular.factor = parseFloat(this.regular.factor).toFixed(2);
      this.health.factor = parseFloat(this.health.factor).toFixed(2);

      let m = this.regular.factor * 100;
      let n = this.health.factor * 100;
      if (m < 0 || m > 100 || n < 0 || n > 100) {
        // this.regular.factor = "";
        // this.health.factor = "";
        this.$message.error("输入为两位正0至1小数");
        return;
      }
      if (flag == 1) {
        this.regular.factor = ((100 - n) / 100).toFixed(2);
      } else {
        this.health.factor = ((100 - m) / 100).toFixed(2);
      }
    },
    changeText(e) {
      console.log("quanzhong---e", e, "=======", e.target["attributes"]["data-id"]["value"], this.toggle);

      console.log("this.toggle", this.toggle);

      this.$forceUpdate();
      if (e.target["attributes"]["data-id"]["value"] == 3) {
        this.regular.factor = this.regular.oldFactor;
        this.health.factor = this.health.oldFactor;
        this.toggle = !this.toggle;
        return;
      }
     

      let m = this.regular.factor * 100;
      let n = this.health.factor * 100;
      if (m < 0 || m > 100 || n < 0 || n > 100) {
        // this.regular.factor = "";
        // this.health.factor = "";
        this.$message.error("输入为两位正0至1小数");
        return;
      }
      this.toggle = !this.toggle;

      if (e.target["attributes"]["data-id"]["value"] == 2) {
        this.saveWeight();
      }
    },
    changeNumber(e) {
      console.log("e", e);
      var value = e;
      var num = /^[0-9]*$/; //^符号是取反  数字的正则
      //   var num  = /[\u4E00-\u9FA5]/g;
      this.numberPass = 0;
      if (!num.test(value)) {
        console.log("请输入正确数字");
        this.$message.error("请输入正确数字");
        return;
      }

      if (Number(value) < 0 || Number(value) > 1) {
        this.$message.error("请输入0到1之间的值");
        return;
      }

      this.numberPass = 1;
    },

    changeNumber1(e) {
      console.log("e", e);
      var value = e;
      var num = /^[0-9]*$/; //^符号是取反  数字的正则
      //   var num  = /[\u4E00-\u9FA5]/g;

      this.numberPass = 0;
      if (!num.test(value)) {
        console.log("请输入正确数字");
        this.$message.error("请输入正确数字");
        return;
      }

      if (Number(value) < 0 || Number(value) > 100) {
        this.$message.error("请输入0到100之间的值");
        return;
      }

      this.numberPass = 1;
    },

    // 确认是否提交
    sureClose() {
      this.sureWin = 0;
    },

    //显示确认窗口
    countData() {

      if (this.health.factor === null || this.regular.factor === null) {
        this.$message.error("请设置权重！");
        return
    
      }

      // this.sureWin = 1;
      this.$confirm(`<i class="el-icon-warning"></i>请确认是否应用该设置`, "提示", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          // this.httpDel(item.id);
          this.surebtn();
        })
        .catch(function () {});
    },

    // 确认重新计算
    surebtn() {
      this.listData();
      // let data = {
      //   healthFactor: this.health.factor.toString(),
      //   regularFactor: this.regular.factor.toString(),
      //   taskHisSettingsBOS: this.arr
      // };
      console.log('surebtn', this.arr,this.health.factor,this.regular.factor)
      

      this.$axios.post(`${this.baseURL}health/his/save/${this.id}?healthFactor=${this.health.factor?this.health.factor.toString():null}&regularFactor=${this.regular.factor?this.regular.factor.toString():null}`, this.arr).then((res) => {
        console.log("重新计算", res);
        this.sureWin = 0;
        this.back();
        if (res.data.errCode == 10000) {
          this.$message.error(res.data.errMsg);
        }
      });
    },

    // 关闭错误窗口
    errClose() {
      this.errWin = 0;
    },
    errbtn() {
      this.errWin = 0;
    },

    // 删除的按钮切换回可用
    getDetWin() {
      if (this.detStatus == 0) {
        this.detStatus = 1;
      } else {
        this.detStatus = 0;
      }
    },

    //打开删除窗口
    detWinOpen(index) {
      this.detIndext = index;
      // this.detWin = 1;
      console.log("index", index);

      this.$confirm(`<i class="el-icon-warning"></i>请确认是否删除该构件/指标？`, "确认删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          // this.httpDel(item.id);
          console.log("this.dataList", this.dataList, this.dataList.length, index);
          this.dataList.splice(index, 1);
          console.log("this.dataList2222", this.dataList, this.dataList.length, index);
        })
        .catch(function () {});
    },

    // 关闭删除窗口
    detWinClose() {
      this.detWin = 0;
    },

    //删除行
    detLine() {
      var index = this.detIndext;
      this.dataList.splice(index, 1);
      this.detWin = 0;
    },

    // 查看
    checkData() {
      this.$axios.get(`${this.baseURL}health/info/${this.id}`).then((res) => {
        console.log("查询分数", res);
        this.sbci = res.data.data.sbci; //下部评分
        this.spci = res.data.data.spci; //上部评分
        this.bdci = res.data.data.bdci; //桥面
        this.grade = res.data.data.grade; //评分

        this.health = {
          grade: res.data.data.grade,
          score: res.data.data.score,
          timeO: res.data.data.timeO,
          sbci: res.data.data.sbci,
          spci: res.data.data.spci,
          bdci: res.data.data.bdci,
          factor: res.data.data.factor,
          oldFactor: res.data.data.factor
        };
        for (let i in this.regular) {
          this.regular[i] = "";
        }
        if (res.data.data.regular) {
          this.regular = {
            score: res.data.data.regular.score,
            timeO: res.data.data.regular.timeO,
            sbci: res.data.data.regular.sbci,
            spci: res.data.data.regular.spci,
            bdci: res.data.data.regular.bdci,
            factor: res.data.data.regular.factor,
            oldFactor: res.data.data.regular.factor
          };
        }

        this.dataList = res.data.data.list;
        this.partData(); //部位权重
        this.partData1();
      });
    },

    // 取消返回
    back() {
      // this.$router.push("/healthy");
      this.$router.go(-1);
    },

    //   点击增加构件
    getWin() {
      this.partsDownData(); //获取部位下拉
      this.addWin = 1;
    },

    //   窗口的部位下拉
    partsDownData() {
      this.$axios.get(`${this.baseURL}health/part`).then((res) => {
        console.log("部位下拉", res);
        this.partDownList = res.data.data;
      });
    },
    //  窗口
    changeDownPart(id) {
      this.sysDownList = [];
      this.sys = "";
      this.$axios.get(`${this.baseURL}health/part/${this.projectId}/${id}`).then((res) => {
        console.log("获取系统下拉", res);
        this.sysDownList = res.data.data;
      });
    },
    // 窗口
    changeDownSys() {},
    // 关闭窗口
    closeWin() {
      this.addWin = 0;
    },
    // 保存增加构件
    winSave() {
      this.listData();
      console.log("arr", this.arr, "part", this.part, "sys", this.sys, "inputName", this.inputName);

      this.$axios.post(`${this.baseURL}health/add/quota?partId=${this.part}&systemId=${this.sys}&component=${this.inputName}`, this.arr).then((res) => {
        console.log("增加构件", res);
        this.dataList = res.data.data;
        this.partData();
        this.addWin = 0;
      });
    },

    //  部件选择
    changePart(index) {
      console.log("val", index);
      console.log("parth", this.part[index]);
      var num = this.part[index];
      this.sysData(num); //获取相应系统下拉
    },

    // 系统
    changeSys(index) {},

    // 获取表格内容  :class="[{'redColor': (item.state == 1 )},{'blueColor': (item.state ==2)},{'yellowColor': (item.state ==3)}]"
    getBtn() {
      var arr = [];
      for (var i = 0; i < 4; i++) {
        arr.push({ name: this.value[i] });
      }
      console.log("arr", arr);
    },

    // -------------------------   请求数据   ---------------------------------
    // 获取设置列表
    tableData() {
      this.$axios.get(`${this.baseURL}health/settings/${this.projectId}`).then((res) => {
        console.log("设置内容", res);
        this.dataList = res.data.data.list;
        for (let i in this.health) {
          this.health[i] = "";
        }
        if (res.data.data.health) {
          this.health = res.data.data.health;
        }
        if (this.health && this.health.factor) {
          
          this.health.factor = this.health.factor.toFixed(2);
        }
      
        this.health.oldFactor = this.health.factor;
        for (let j in this.regular) {
          this.regular[j] = "";
        }
        if (res.data.data.regular) {
          this.regular = res.data.data.regular;
        }
        if (this.regular && this.regular.factor) {
          
          this.regular.factor = this.regular.factor.toFixed(2);
        }
        this.regular.oldFactor = this.regular.factor;
        this.partData(); //部位权重
        this.partData1();
      });
    },

    // 显示表格
    partData() {
      this.targetList = [];
      this.dataList.forEach((item, index) => {
        // 测点名称
        // item.targetIds = (item.targetIds).split(',');     //字符串变数组  "a,b"   [a,b]

        item.projectId = this.projectId;

        // 部位权重
        if (item.partId == 1) {
          this.partValue1 = item.partFactor;
        }

        if (item.partId == 2) {
          this.partValue2 = item.partFactor;
        }

        if (item.partId == 3) {
          this.partValue3 = item.partFactor;
        }

        // 根据系统id获取不同的下拉测点
        // setTimeout(() => {
        this.getTargetList(item.systemId, index, item);
        // }, 500);

        // 系统权重
        //墩台
        if (item.systemId == 4) {
          this.sysValue4 = item.systemFactor;
        }
        // 基础
        if (item.systemId == 5) {
          this.sysValue5 = item.systemFactor;
        }
        //主塔
        if (item.systemId == 6) {
          this.sysValue6 = item.systemFactor;
        }
        //主缆系统
        if (item.systemId == 7) {
          this.sysValue7 = item.systemFactor;
        }
        // 吊杆系统
        if (item.systemId == 8) {
          this.sysValue8 = item.systemFactor;
        }

        // 悬桥
        // 支座
        if (item.systemId == 9) {
          this.sysValue9 = item.systemFactor;
        }
        //  桥墩
        if (item.systemId == 13) {
          this.sysValue13 = item.systemFactor;
        }
        //  桥墩
        if (item.systemId == 18) {
          this.sysValue18 = item.systemFactor;
        }

        // 附属设施
        if (item.systemId == 10) {
          this.sysValue10 = item.systemFactor;
        }

        //  桥墩
        if (item.systemId == 15) {
          this.sysValue15 = item.systemFactor;
        }
        // 桥台
        if (item.systemId == 16) {
          this.sysValue16 = item.systemFactor;
        }
        // 墩台基础
        if (item.systemId == 17) {
          this.sysValue17 = item.systemFactor;
        }

        this.componentValue[index] = item.componentFactor;

        // 修改值
        this.gradeUpdate[index] = item.gradeUpdate;

        // setTimeout(() => {
        //     console.log('66');
        //     this.targetIds[index] = item.targetId;     //选中
        // }, 200);
      });
    },
    partData1() {
      this.dataList.forEach((item, index) => {
        console.log("66");
        this.targetIds[index] = item.targetId;
      });
      console.log("targetIds", this.targetIds);
    },

    // 根据测点id获取不同下拉测点
    getTargetList(id, index, item) {
      console.log("index", index, id, item);
      // setTimeout(() => {
      this.$axios.get(`${this.baseURL}health/target/${id}?projectId=${this.projectId}`).then((res) => {
        console.log("res测点列表 index", index, res);
        // this.targetList.push(res.data.data);
        this.targetList[index] = res.data.data;
        // console.log('targetList', this.targetList);
        this.$forceUpdate();
      });

      // }, 1000);
    },

    // 保存
    saveBtn() {
      this.listData();
      if (!this.health.factor && !this.regular.factor) {
        this.$message.error("请填写权重系数");
        return
      }
      let data = {
        healthFactor: this.health.factor.toString(),
        regularFactor: this.regular.factor.toString(),
        taskHisSettingsBOS: this.arr
      };

      console.log("arr提交", this.arr, data);

      this.$axios.post(`${this.baseURL}health/grade/save/${this.projectId}?healthFactor=${this.health.factor.toString()}&regularFactor=${this.regular.factor.toString()}`, this.arr).then((res) => {
        console.log("保存", res);
        // 10001
        if (res.data.errCode == "10001") {
          // this.$message.error(res.data.errMsg);
          // this.errWin = 1;
          this.errName = res.data.errMsg;

          this.$confirm(`<i class="el-icon-warning"></i>${this.errName}`, "提示", {
            dangerouslyUseHTMLString: true,
            cancelButtonText: "取消",
            confirmButtonText: "确定",
            closeOnClickModal: false,
            cancelButtonClass: "btn-cancel",
            confirmButtonClass: "btn-confirm"
          })
            .then(() => {
              // this.httpDel(item.id);
            })
            .catch(function () {});

          return;
        }
        if (res.data.errCode == "10000") {
          // this.$message.error(res.data.errMsg);
          // this.errWin = 1;
          this.errName = res.data.errMsg;
          this.$confirm(`<i class="el-icon-warning"></i>${this.errName}`, "提示", {
            dangerouslyUseHTMLString: true,
            cancelButtonText: "取消",
            confirmButtonText: "确定",
            closeOnClickModal: false,
            cancelButtonClass: "btn-cancel",
            confirmButtonClass: "btn-confirm"
          })
            .then(() => {
              // this.httpDel(item.id);
            })
            .catch(function () {});

          return;
        }

        this.$message.success("保存成功");
      });
    },

    listData() {
      var arr = [];
      arr = this.dataList;
      arr.forEach((item, index) => {
        // 部位权重
        if (item.partId == 1) {
          item.partFactor = this.partValue1;
        }
        if (item.partId == 2) {
          item.partFactor = this.partValue2;
        }
        if (item.partId == 3) {
          item.partFactor = this.partValue3;
        }

        // 系统权重
        if (item.systemId == 4) {
          item.systemFactor = this.sysValue4;
        }
        if (item.systemId == 5) {
          item.systemFactor = this.sysValue5;
        }
        if (item.systemId == 6) {
          item.systemFactor = this.sysValue6;
        }
        if (item.systemId == 7) {
          item.systemFactor = this.sysValue7;
        }
        if (item.systemId == 8) {
          item.systemFactor = this.sysValue8;
        }

        // 悬桥
        // 支座
        if (item.systemId == 9) {
          item.systemFactor = this.sysValue9;
        }
        // 主梁
        if (item.systemId == 13) {
          item.systemFactor = this.sysValue13;
        }
        // 上部承重结构
        if (item.systemId == 18) {
          item.systemFactor = this.sysValue18;
        }

        // 附属设施
        if (item.systemId == 10) {
          item.systemFactor = this.sysValue10;
        }

        // 桥墩
        if (item.systemId == 15) {
          item.systemFactor = this.sysValue15;
        }
        // 桥台
        if (item.systemId == 16) {
          item.systemFactor = this.sysValue16;
        }
        // 墩台基础
        if (item.systemId == 17) {
          item.systemFactor = this.sysValue17;
        }

        // 构件权重
        item.componentFactor = this.componentValue[index];
        item.targetId = this.targetIds[index];
        item.targetIds = "";

        // 修改值
        if (this.type == "edit") {
          item.gradeUpdate = this.gradeUpdate[index];
        }
      });

      this.arr = arr;
    },

    // 历史设置
    hisData() {
      // numList
      this.$axios.get(`${this.baseURL}health/select/his/${this.projectId}`).then((res) => {
        console.log("历史列表", res);
        this.numList = res.data.data;
      });
    },

    // 获取历史
    changeHis() {
      //  this.setNum = 1;
      this.dataList = [];
      this.$axios.get(`${this.baseURL}health/info/${this.num}`).then((res) => {
        console.log("历史内容", res);
        // this.numList = res.data.data;
        this.dataList = res.data.data.list;
        this.partData(); //部位权重
        this.partData1();
      });
    }
  }
};
</script>
<style scoped lang="scss">
.healthySet {
  width: 100%;
  height: 100%;
  // overflow-y: hidden;
}
.table-weight-wrapper td {
  height: 40px;
  text-align: center;
}
.table-weight-wrapper td input {
  height: 100%;
  background-color: transparent;
  color: #fff;
  padding-left: 20px;
  outline: none;
}
.table-weight-wrapper .table-weight {
  width: 200px;
  text-align: left;
  .table-weight-res {
    padding-left: 20px;
    display: inline-block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    line-height: 40px;
  }
  .weight-text {
    float: left;
    padding-left: 20px;
  }
  .weight-confirm {
    float: right;
    padding-right: 20px;
    font-family: PingFangSC-S0pxibold;
    font-weight: 600;
    font-size: 16px;
    color: #2667db;
    letter-spacing: 0.93px;
    span {
      cursor: pointer;
    }
  }
}

::v-deep .border-table {
  border-collapse: collapse;
  border: none;

  tr {
    width: 100%;
  }
}

.border-table td {
  padding-left: 10px;
  border: 1px solid #2772f0;
}

.border-table th {
  height: 40px;
  padding-left: 10px;
  border: 1px solid #2772f0;
}

::v-deep .el-select {
  width: 100% !important;
}

::v-deep .el-input .el-input__inner {
  border: none !important;
  width: 100% !important;
  height: 40px;
  background-color: transparent !important;
}

.partValue1 {
  background: #042e7b !important;
}

.partValue2 {
  background: #11306e !important;
}

.sysValue9 {
  background: #172f79 !important;
}

.sysValue10 {
  background: #03397c !important;
}

.sysValue13 {
  background: #102777 !important;
}

.sysValue15 {
  background: #0363b6 !important;
}

.sysValue16 {
  background: #1b3fa1 !important;
}

.sysValue17 {
  background: #0a2c8b !important;
}

.sysValue18 {
  background: #0149a6 !important;
}

.boxBottom {
  margin-top: 15px;
  border: 1px solid #245dbd;
  height: 40px;
  line-height: 40px;
  border-right: none;

  .itemBox {
    border-right: 1px solid #245dbd;
  }
}

// 添加弹窗
::v-deep .addWin {
  width: 788px;
  height: 422px;
  position: absolute;
  left: 50%;
  margin-left: -394px;
  z-index: 4;

  .heng {
    height: 1px;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 30px;
  }

  .tableWin td,
  .tableWin th {
    border: 1px solid rgba($color: #ccdaff, $alpha: 0.3);
    height: 40px;
    line-height: 40px;
  }

  .el-select .el-input {
    // border: 1px solid rgba(49, 102, 205, 0.82) !important;
  }
}

// .detWin {
//   text-align: center;
//   position: absolute;
//   left: 50%;
//   top: 30%;
//   z-index: 2;
//   width: 500px;
//   margin-left: -250px;
//   height: 300px;
//   background: #ffffff;
//   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
//   border-radius: 12px;
//   padding-top: 66px;
// }

// .btn1 {
//   text-align: center;
//   line-height: 32px;
//   width: 96px;
//   height: 32px;
//   background: #ececec;
//   border-radius: 20px;
//   color: #999;
// }
// .btn2 {
//   text-align: center;
//   line-height: 32px;
//   width: 96px;
//   height: 32px;
//   background: #2667db;
//   border-radius: 20px;
// }

.bg1 {
  background-color: #061532;

  span {
    color: #5a5a5a;
  }
}

::v-deep .uiBox {
  .el-select {
    background: #001b4a;
    border-radius: 16px;
    border: 1px solid rgba(49, 102, 205, 0.82);
  }
}
.pointer1 {
  cursor: not-allowed;
}
</style>

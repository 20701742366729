export const Enotation = function (val) {
  if (!val) return val;
  let logo = "";
  let num = val;
  if (Number(val) < 0) {
    logo = "-";
    num = val.split("-")[1];
  }
  const result = num.split("");
  let position = result.indexOf(".");
  position = position !== -1 ? position : result.length;
  while (position > 3) {
    position -= 3;
    result.splice(position, 0, ",");
  }
  return logo + result.join("");
};

//  数组去重
export const formateArray = function (arr) {
  console.log("arr", arr);
  if (!arr || !arr.length) return [];
  let newArr = [],
    namearr = [],
    map = new Map();
  for (let i = 0; i < arr.length; i++) {
    // console.log("=========", map.has(arr[i]), map.get(arr[i]));
    // if (!map.get(arr[i])) {
    //   console.log(map, arr[i]);
    //   map.set(arr[i], true);
    //   newArr.push(arr[i]);
    // }
    if (!namearr.includes(arr[i].name)) {
      newArr.push(arr[i]);
      namearr.push(arr[i].name);
    }
  }
  console.log("newarr", newArr);
  return newArr;
};


export const trim = (str) => {
  console.log('str', str, typeof str)
  if (!str || str==="NaN"  ) return ""
  if ((typeof str) === "number") {
    str=str.toString()
  }
 
  
  return str.replace(/(^\s*)|(\s*$)/g, '');
}

